@font-face {
  font-family: Roboto400;
  src:
    url('~typeface-roboto/files/roboto-latin-400.woff2') format('woff2'),
    url('~typeface-roboto/files/roboto-latin-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto300;
  src:
    url('~typeface-roboto/files/roboto-latin-300.woff2') format('woff2'),
    url('~typeface-roboto/files/roboto-latin-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Roboto500;
  src:
    url('~typeface-roboto/files/roboto-latin-500.woff2') format('woff2'),
    url('~typeface-roboto/files/roboto-latin-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/*
  The purpose of defining class stages is to
  re-render once a stage has been met. We start
  with the minimal default stage of sans-serif,
  and progressively re-render.
*/
html, body {
  font-family: sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: rgba(255, 255, 255, 0.7);
}

/*
  The defined stages now modify the display of
  elements once they are loaded.
*/

/*
  During primary stage we only load the Roboto font.
  Once it's loaded, update the body to use it.
*/
.font-stage-primary html,
.font-stage-primary body {
  font-family: Roboto400, sans-serif;
}

/* Prevent the secondary fonts from being tree-shaken away */
.font-stage-secondary .roboto300 {
  font-family: Roboto300, sans-serif;
}
.font-stage-secondary .roboto500 {
  font-family: Roboto500, sans-serif;
}
